<script>

import { findEventsList } from '@/api/index'

export default {
  name: 'Events',
  data () {
    return {
      eventsData: [],
    }
  },
  methods: {
    getData () {
      findEventsList().then(res => {
        if (res.code == 200) {
          this.eventsData = res.data.list
          this.eventsData.forEach(item => {
            item.eventsTime = item.eventsTime.split(' ')[0]
          })
        }
      })
    },
    JoinClick (url) {
      //新页面打开
      if(url) {
        window.open(url)
      }
    }
  },
  created () {
    this.getData()
  }
}

</script>


<template>
  <div class="page">
    <div class="block-full-width events">
      <div class="main">
        <div class="title title-events"></div>
        <div class="events-item" v-for="(item, index) in eventsData" :key="index">
          <img :src="item['image']" class="event-img" />
          <div class="events-info">
            <div class="events-title">{{ item.title }}</div>
            <div class="events-desc">{{ item.description }}</div>
            <div class="events-ctrl-bar">
              <div class="events-date">{{ item.eventsTime}}</div>
              <div class="events-place">{{ item.address }}</div>
              <div class="btn" @click="JoinClick(item.url)" :style="item.url?'':'backgroundColor:gray;cursor:not-allowed'">JOIN+</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.events {
  background-image: url('../assets/images/events/bg-events.jpg');
  padding-bottom: 100px;
  min-height: var(--bodyContentMinHeight);
}
.title-events {
  background-image: url('../assets/images/common/title-events.png');
}
.events-item {
  background: #fff;
  height: 330px;
  margin-bottom: 50px;
  position: relative;
  box-shadow: 0 0 20px 1px rgba(128, 204, 255, 0.2);
}

.event-img {
  float: left;
  margin-right: 30px;
  width: 582px;
  height: 330px;
}
.events-item:nth-of-type(2n + 1) .event-img {
  float: right;
  margin-left: 30px;
  margin-right: 0;
}

.events-info {
  color: var(--main-color-1);
  padding: 20px 30px 20px 0;
  position: static;
}
.events-item:nth-of-type(2n + 1) .events-info {
  padding-left: 40px;
}
.events-title {
  font-size: var(--font-size-xl);
  font-weight: 600;
  margin-bottom: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.events-ctrl-bar {
  position: absolute;
  right: 30px;

  bottom: 20px;
  width: 560px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #c2d6ec;
  padding-top: 20px;
}

.events-item:nth-of-type(2n + 1) .events-ctrl-bar {
  right: unset;
  left: 30px;
}
.events-date {
  background: url('../assets/images/events/icon-time.png') no-repeat left center;
  padding-left: 29px;
  line-height: 30px;
}
.events-place {
  background: url('../assets/images/events/icon-local.png') no-repeat left center;
  padding-left: 29px;
  line-height: 24px;
  /* left: -20px; */
  width: 220px;
}
.events-desc {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
}
@media (max-width: 768px) {
  .events {
    background-image: url('../assets/images/events/m/bg-events-m.jpg');
    padding-bottom: 0.5rem;
  }
  .events-item {
    margin-bottom: 0.4rem;
    height: auto;
  }
  .event-img {
    float: none;
    width: 100%;
    height: auto;
    margin-right: 0;
    aspect-ratio:16/9
  }
  .events-item:nth-of-type(2n + 1) .event-img {
    float: none;
    margin-left: 0;
    margin-right: 0;
  }

  .events-info {
    padding: 0.3rem 0.4rem;
  }
  .events-item:nth-of-type(2n + 1) .events-info {
    padding: 0.3rem 0.4rem;
  }

  .events-title {
    margin-bottom: 0.2rem;
  }
  .events-ctrl-bar {
    display: block;
    position: static;
    width: 100%;
    padding-top: 0.3rem;
    margin-top: 0.3rem;
  }
  .events-item:nth-of-type(2n + 1) .events-ctrl-bar {
    position: static;
    width: 100%;
    padding-top: 0.3rem;
    margin-top: 0.3rem;
  }

  .events-ctrl-bar .btn {
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    display: inline-block;
    vertical-align: text-bottom;
  }
  .events-date {
    margin-right: 1rem;
    padding-left: 0.3rem;
    background-size: 0.22rem 0.225rem;
  }
  .events-place {
    background-size: 0.22rem 0.25rem;
    padding-left: 0.3rem;
    width: 4rem;
    display: inline-block;
  }
}
</style>