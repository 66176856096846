<script>

import { findProgramsList, findPartnershipList } from '@/api/index'


export default {
  name: 'About',
  data () {
    return {
      programsData: [],
      partnershipData: [],
    }
  },
  methods: {
    getData () {
      findProgramsList().then(res => {
        if (res.code == 200) {
          this.programsData = res.data.list
        }
      })
      findPartnershipList().then(res => {
        if (res.code == 200) {
          this.partnershipData = res.data
        }
      })
    },
    //合作伙伴跳转
    listUrl (url) {
      if (url) {
        window.open(url)
      }
    }
  },
  created () {
    this.getData()
  }
}



</script>

<template>
  <div class="page">
    <div class="block-full-width about">
      <div class="main">
        <div class="title title-about"></div>

        <div class="intro-container">
          <div class="intro-top">
            <img class="logo-cysc" src="../assets/images/about/logo-cysc.png" alt="" />
            <div class="intro-big">
              Children & Youth Science Center (CYSC), a non-profit organization affiliated to China Association for
              Science and Technology (CAST), was founded in 1978. CYSC is committed to engaging the public with science
              and technology and inspiring innovation in younger generation through science education programs and
              public events. CYSC, together with provincial branches, science museums and STE centers have made up a
              nationwide network for informal science education and science popularization events in China.
            </div>
          </div>

          <div class="title title-goals"></div>
          <div class="text-block-container">
            <div class="text-block blue block-1">
              Encourage young talents to pursue science careers and foster future scientists and engineers
            </div>
            <div class="text-block yellow block-2">
              Inspire children’s inter ests and curiosity in science and technology
            </div>
            <div class="text-block red block-3">Promote public science literacy</div>
            <div class="text-block blue block-4">
              Establish cooperation and partnership in science education and popularization
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="block-full-width programs">
      <div class="main">
        <div class="title title-programs"></div>
        <div class="program-item" v-for="(item, index) in programsData" :key="index">
          <div class="logo-container" @click="listUrl(item.url)" :style="item.url? 'cursor:pointer' : 'cursor:default'">
            <img :src="item['image']" alt="" />
          </div>
          <div class="programs-title">{{ item.title }}</div>
          <div class="programs-desc">{{ item.description }}</div>
        </div>
        <div class="participate">
          <div class="intro-big">Exchange Activities and Contests for Young Scientists We Participate in</div>
          <img class="img-map" src="../assets/images/about/map.jpg" alt="" />
        </div>
      </div>
    </div>
    <div class="block-full-width partnership">
      <div class="main">
        <div class="title title-partnership"></div>
        <div class="partnership-container">
          <div class="intro-big">
            Our programs are widely supported by government departments, social organizations and foundations. CYSC
            appreciates partnerships with academic institutes, universities and industries on high-quality science
            education and communication to achieve our missions. Together with governmental departments and
            organizations, CYSC makes all programs sustainable, successful and fruitful:
          </div>
          <ul class="clearfix">
            <li v-for="(item, index) in partnershipData" :key="index" @click="listUrl(item.url)" :style="item.url? 'cursor:pointer' : ''">
              {{ item.name }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.about {
  background-image: url('../assets/images/about/bg-aboutus.jpg');
  padding-bottom: 50px;
  background-size: cover;
  background-position-y: bottom;
}
.intro-top {
  display: flex;
  align-items: center;
}
.logo-cysc {
  width: 204px;
  height: 135px;
}

.intro-container .intro-big {
  margin-left: 50px;
  border-left: 1px solid #c2d6ec;
  padding-left: 50px;
  font-size: var(--font-size-m);
  font-weight: 600;
  line-height: 2;
}
.programs {
  background-image: url('../assets/images/about/bg-programs.jpg');
  padding-bottom: 30px;
  padding-top: 1px;
  background-color: #f8f9fb;
}
.partnership {
  background-image: url('../assets/images/about/bg-partnership.jpg');
  padding-bottom: 100px;
  padding-top: 100px;
  color: var(--main-color-1);
}
.title-about {
  background-image: url('../../public/images/common/title-aboutus.png');
}
.title-goals {
  background-image: url('../../public/images/common/title-goals.png');
}
.title-programs {
  background-image: url('../../public/images/common/title-programs.png');
}
.title-partnership {
  background-image: url('../../public/images/common/title-partnership.png');
}
.intro-container {
  color: var(--main-color-1);
}

.intro-small {
  line-height: 2;
}
.btn {
  margin: 50px auto 100px;
}
.entry-banner {
  margin: 25px auto;
}
.text-block-container {
  white-space: nowrap;
  margin-bottom: 80px;
}
.text-block {
  width: 319px;
  height: 234px;
  color: #fff;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 50px 40px 0px 62px;
  font-weight: 550;
  letter-spacing: 0.02em;
  line-height: 2;
  display: inline-block;
  vertical-align: top;
  white-space: normal;
  margin: -15px;
}
.text-block.blue {
  background-image: url('../assets/images/about/bg-goals-1.png');
}
.text-block.yellow {
  background-image: url('../assets/images/about/bg-goals-2.png');
}
.text-block.red {
  background-image: url('../assets/images/about/bg-goals-3.png');
}
.text-block.block-3,
.text-block.block-4 {
  margin-top: 85px;
}
.program-item {
  background: url('../assets/images/about/bg-text.png') no-repeat center;
  width: 1120px;
  height: 282px;
  margin: 30px 0 0 80px;
  padding-top: 0.1px;
}
.logo-container {
  background: url('../assets/images/about/bg-logo.jpg') no-repeat center;
  background-size: 100% 100%;
  width: 379px;
  height: 214px;
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  margin-left: -80px;
  margin-top: 34px;
}
.logo-container img {
  /* width: 379px;
    height: 214px; */
}
.programs-title {
  font-size: var(--font-size-l);
  margin: 25px 0 20px 0;
  font-weight: 600;
  margin-left: 320px;
}
.programs-desc {
  margin-left: 320px;
  padding-right: 30px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 7;
}

.participate {
  text-align: center;
}
.participate .intro-big {
  font-size: var(--font-size-xl);

  color: var(--main-color-1);
  margin: 80px auto 40px;
  font-weight: 600;
}

.partnership .partnership-container {
  padding: 45px;

  background-image: -moz-linear-gradient(0deg, rgb(254, 255, 255) 0%, rgb(184, 218, 255) 100%);
  background-image: -webkit-linear-gradient(0deg, rgb(254, 255, 255) 0%, rgb(184, 218, 255) 100%);
  background-image: -ms-linear-gradient(0deg, rgb(254, 255, 255) 0%, rgb(184, 218, 255) 100%);
  background-image: linear-gradient(90deg, rgb(254, 255, 255) 0%, rgb(184, 218, 255) 100%);
}
.partnership .intro-big {
  font-weight: 600;
  line-height: 2;
}
.partnership ul {
  margin-top: 30px;
}
.partnership ul li {
  float: left;
  width: 50%;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .intro-top {
    display: block;
  }
  .logo-cysc {
    width: 3.4rem;
    height: 2.24rem;
    display: block;
    margin: 0 auto;
  }
  .intro-container .intro-big {
    border-left: 0;
    margin: 0;
    padding-left: 0;
    margin-top: 0.8rem;
    font-size: var(--font-size-m-m);
  }

  .programs {
    background-image: url('../assets/images/about/m/bg-programs-m.jpg');
    background-size: 100% auto;

    padding-bottom: 0rem;
  }
  .text-block-container {
    white-space: normal;
    margin-bottom: 0.3rem;
  }
  .text-block {
    margin: 0.25rem auto;
    display: block;
    width: 6.87rem;
    height: 2.62rem;
    padding: 0.4rem 1rem;
  }
  .text-block.block-3,
  .text-block.block-4 {
    margin: 0.25rem auto;
  }

  .text-block.blue {
    background-image: url('../assets/images/about/m/bg-goals-1-m.png');
  }
  .text-block.yellow {
    background-image: url('../assets/images/about/m/bg-goals-2-m.png');
  }
  .text-block.red {
    background-image: url('../assets/images/about/m/bg-goals-3-m.png');
  }

  .program-item {
    width: 100%;
    margin: 1.7rem auto 0.5rem;
    height: auto;
    background: url('../assets/images/about/m/bg-text-m.png') no-repeat center;
    background-size: cover;
  }
  .logo-container {
    width: 6.14rem;
    height: 3.47rem;
    margin: 0 auto;
    float: none;
    background-image: url('../assets/images/about/m/bg-logo-m.png');
    margin-top: -1.5rem;
  }
  .programs-title {
    margin: 0.2rem 0.25rem;
    font-size: var(--font-size-l-m);
  }
  .programs-desc {
    margin: 0.2rem 0.25rem;
    padding: 0 0 0.6rem 0;
    display: block;
  }
  .img-map {
    width: 100%;
  }

  .participate .intro-big {
    font-size: var(--font-size-xl-m);

    color: var(--main-color-1);
    margin: 1rem auto 0.8rem;
  }

  .partnership {
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
    background-image: url('../assets/images/about/m/bg-partnership-m.jpg');
  }
  .partnership .partnership-container {
    padding: 0.35rem;
  }
  .partnership ul {
    margin: 0.3rem;
    padding-left: 0;
  }
  .partnership ul li {
    float: none;
    width: 100%;
    margin-bottom: 0.1rem;
  }
}
</style>