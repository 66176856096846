<template>
  <div class="news">
    <div class="main">
      <div class="title title-news"></div>
      <div class="cards-container">
        <CardSimple
          v-for="(item, index) in newsData"
          :key="index"
          :src="item['image']"
          :link="'article'"
          :id="item.id"
          :date="item.publishTime"
          :title="item.title"
          :height="pageWidth > 768 ? '380px' : '6.04rem'"
        />
        <infinite-loading @infinite="infiniteHandler" force-use-infinite-wrapper v-if="pageWidth < 768">
          <div slot="no-more" class="no-more">No more Data</div>
        </infinite-loading>
      </div>
      <div class="pagination-box" v-if="pageWidth > 768">
        <el-pagination
          :hide-on-single-page="true"
          prev-text="before"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          next-text="next"
          layout="prev, pager, next"
          :page-size="size"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import CardSimple from "../components/CardSimple.vue"
import InfiniteLoading from 'vue-infinite-loading'
import { findNewsList } from "@/api/index"
import { FormItem } from "element-ui"
export default {
  name: "News",
  components: { CardSimple, InfiniteLoading, },
  data () {
    return {
      newsData: [],
      page: 1,
      size: 9,
      total: 0,
      pageWidth: window.innerWidth,
    }
  },
  methods: {
    getData ($state) {
      findNewsList(this.page, this.size).then((res) => {
        if (res.code == 200) {
          this.total = res.data.count
          res.data.list.forEach(item => {
            const date = new Date(item.publishTime)
            item.publishTime = date.toLocaleDateString('en-us', { year: 'numeric', month: 'long', day: 'numeric' })
          })
          if ($state) {
            console.log($state);
            if (res.data.list.length) {
              this.page += 1
              console.log(this.page);
              this.newsData.push(...res.data.list)
              $state.loaded() // 通知组件加载数据
            
            } else {
              $state.complete() //数据加载完
            }
          } else {
            this.newsData = res.data.list
          }

        }
      })
    },
    // 移动端无限加载
    infiniteHandler ($state) {
      this.getData($state)
    },
    handleCurrentChange (val) {
      this.page = val
      this.getData()
    },
    handleSizeChange (val) {
      this.size = val
      this.getData()
    },
  },
  mounted () {


  },
  created () {
    if (window.innerWidth > 768) {
      this.getData()
    }
  },
};
</script>
<style scoped>
.cards-container {
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
  overflow: auto;
  flex-wrap: wrap;
}
.title.title-news {
  margin-top: 218px;
  background-image: url('../../public/images/common/title-news.png');
}
.no-more {
  color: #7a7a7a;
  margin-bottom: 0.45rem;
}
.card-simple {
  margin-bottom: 30px;
}
.news {
  background-image: url('../assets/images/article/bg.jpg');
  padding-top: 0.1px;
  padding-bottom: 0.1px;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100% 100%;
  min-width: 1200px;
}
@media (max-width: 768px) {
  .news {
    background-image: url('../assets/images/home/m/bg-news-m.jpg');
    min-width: 6.05rem;
  }
  .title.title-news {
    margin-top: 2.18rem;
    background-image: url('../../public/images/common/title-news-m.png');
  }
  .cards-container {
    flex-direction: column;
    padding-bottom: 0rem;
  }
}
.pagination-box {
  text-align: center;
  margin-top: 53px;
  margin-bottom: 106px;
}
.pagination-box .el-pagination /deep/ .btn-prev,
/deep/.btn-next {
  color: #7a7a7a !important;
  width: 69px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  padding: 0 !important;
  font-size: 14px;
}
.pagination-box .el-pagination /deep/ .el-pager li {
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  height: 32px;
  color: #7a7a7a;
  line-height: 32px;
  font-size: 14px;
  margin: 0 4px;
}
.pagination-box .el-pagination /deep/.el-pager li.active {
  background: #2e459f;
  color: #fff;
}
</style>
