import request from '@/utils/request'
//首页轮播图
export const findSwiperList = (data) => {
    return request({
      method: 'GET',
      url: '/api/findSwiperList',
      data,
      formdata:true
      
    })
  }
// 获取新闻卡片
export const findNewsCardList = (data) => {
    return request({
      method: 'GET',
      url: '/api/findNewsCardList',
      data,
      formdata:true
      
    })
  }
// 获取首页活动轮播
export const finEventsSwiperList = (data) => {
    return request({
      method: 'GET',
      url: '/api/finEventsSwiperList',
      data,
      formdata:true
      
    })
  }
// 分页查询活动列表
export const findEventsList = (data) => {
    return request({
      method: 'GET',
      url: '/api/findEventsList',
      data,
      formdata:true
      
    })
  }

  // 分页查询关于我们活动列表
export const findProgramsList = (data) => {
    return request({
      method: 'GET',
      url: '/api/findProgramsList',
      data,
      formdata:true
      
    })
  }

  //查询合作伙伴列表
export const findPartnershipList = (data) => {
    return request({
      method: 'GET',
      url: '/api/findPartnershipList',
      data,
      formdata:true
      
    })
  }
// 根据id查询活动详情   
export const finEventsSwiperById = (data) => {
    return request({
      method: 'GET',
      url: '/api/finEventsSwiperById',
      data,
      formdata:true
      
    })
  }
// 根据id获取新闻详情
export const findNewsCardById = (id) =>{
    return request({
        method: 'GET',
        url: '/api/findNewsCardById?id='+id,
    })
}
// 
export const findNewsList = (page,size) =>{
    return request({
        method: 'GET',
        url: '/api/findNewsList?page='+page+'&size='+size,
        
    })
}
