import axios from 'axios'
import Qs from 'qs'
import { Message } from 'element-ui'
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASEURL,
  timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    if (config.formdata) {
      config.transformRequest = [
        function(data) {
          data = Qs.stringify(data)
          return data
        }
      ]
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
      config.headers['X-Requested-With'] = 'XMLHttpRequest'
      // return config;
    } else if(config.multipart){
      config.headers['Content-Type'] = 'multipart/form-data'

    }else {
      config.headers['Content-Type'] = 'application/json;charset=UTF-8'
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code !== 200) {
      Message({
        message: res.msg || 'Error',
        type: 'error',
        duration: 5 * 1000
      })

      return Promise.reject(new Error(res.msg || 'Error'))
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.msg,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
