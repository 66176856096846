import 'babel-polyfill'
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './router/index'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
const router = new VueRouter({
  routes,
  base :process.env.VUE_APP_PUBLIC
});
//跳转后回到顶部
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
})
Vue.use(VueRouter);
Vue.prototype.$VUE_APP_PUBLIC = process.env.VUE_APP_PUBLIC;
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')




var screenInit = {
  setDefaultFontSize: function() {
      var fontsizeAll=window.innerWidth / 750 * 100;
      if(parseInt(fontsizeAll)<10){
          window.setTimeout(function(){
              screenInit.setDefaultFontSize();
          },100);
          return;
      }

      document.querySelector('html').style.fontSize = fontsizeAll + 'px';
  },
};
screenInit.setDefaultFontSize();
window.addEventListener('resize',screenInit.setDefaultFontSize,false);

