<script>
import { swiper, swiperSlide } from "vue-awesome-swiper"
import "swiper/dist/css/swiper.css"
import { findSwiperList, findNewsCardList, finEventsSwiperList } from '@/api/index'

import CardSimple from "../components/CardSimple.vue"

const pageWidth = window.innerWidth
export default {
  name: "Home",
  components: { CardSimple, swiper, swiperSlide },
  data () {
    const self = this
    return {
      newsCardData: [],//新闻卡片数据
      eventsSwiperData: [],//活动轮播
      swiperData: [],//banner轮播
      activeIndex: 0,//控制banner title的显示
      eventsSwiperIndex: 0,//控制活动轮播title的显示
      newsSwiperOption: {//banner轮播配置
        loop: true,
        autoplay: true,
        loopFillGroupWithBlank: true,
        observer:true,
        observeParents:true,
        autoplay: {
          delay: 5000,
        },
        pagination: {
          el: ".news-desc .pager",
          clickable: true,
        },
        on: {
          slideChange () {
            self.activeIndex = this.realIndex
          },

        },
      },
      eventsSwiperOption: {//活动轮播配置
        loop: true,
        autoplay: false,
        loopFillGroupWithBlank: true,
        observer:true,
        observeParents:true,
        autoplay: {
          delay: 3000,
        },
        navigation: {
          nextEl: ".event-swiper-control .btn-next",
          prevEl: ".event-swiper-control .btn-prev",
        },
        slidesPerView: pageWidth > 768 ? 2 : 1,
        pagination: {
          clickable: true,
          el: ".event-swiper-control .pager",
          type: "fraction",
        },
        centeredSlides: true,
        effect: pageWidth > 768 ? "coverflow" : "",
        coverflowEffect: {
          rotate: 0,
          stretch: -140,
          depth: 400,
          modifier: 1.6,
          slideShadows: false,
        },
        on: {
          slideChange () {
            //使用activeIndex loop后索引有问题，影响title显示
            self.eventsSwiperIndex = this.realIndex
          },
        },
      },
    }
  },
  methods: {
    jumpUrl (url) {
      if (url.length != 0) {
        window.open(url)
      }
    },
    getData () {
      //获取banner数据
      findSwiperList().then(res => {
        if (res.code == 200) {
          this.swiperData = res.data
        }
      })
      //获取新闻卡片
      findNewsCardList().then(res => {
        if (res.code == 200) {
          this.newsCardData = res.data
        }
      }),
        //获取活动轮播
        finEventsSwiperList().then(res => {
          if (res.code == 200) {
            this.eventsSwiperData = res.data

          }
        })
    },
  },
  created () {
    this.getData()
  },
};
</script>

<template>
  <div class="page">
    <div class="block-full-width news">
      <div class="swiper-news-container">
        <swiper class="swiper-news" :options="newsSwiperOption" v-if="swiperData.length">
          <swiper-slide v-for="(item, index) in swiperData" :key="index">
            <div :style="item.url ? 'cursor:pointer' : 'cursor:auto'" @click="jumpUrl(item.url)">
              <img :src="item['image']" alt="" />
            </div>
          </swiper-slide>
        </swiper>
        <div class="news-desc">
          <div class="news-title" v-if="swiperData[activeIndex]">{{ swiperData[activeIndex].title }}</div>
          <div class="pager"></div>
        </div>
      </div>

      <div class="main">
        <div class="title title-news"></div>

        <div class="cards-container">
          <CardSimple
            v-for="(item, index) in newsCardData"
            :key="index"
            :src="item['image']"
            :link="'article'"
            :id="item.id"
            :title="item.title"
            :desc="item.description"
          />
        </div>
        <div class="cards-btn">
          <router-link to="/news" class="btn" tag="div">MORE</router-link>
        </div>
      </div>
    </div>
    <div class="block-full-width events">
      <div class="main">
        <div class="title title-events"></div>
        <!-- eventsSwiperData.length>0解决loop失效问题 -->
        <swiper class="swiper-events" :options="eventsSwiperOption" v-if="eventsSwiperData.length > 0">
          <swiper-slide v-for="(item, index) in eventsSwiperData" :key="index">
            <div :style="item.url ? 'cursor:pointer' : 'cursor:auto'" @click="jumpUrl(item.url)">
              <img :src="item['image']" alt="" />
            </div>
          </swiper-slide>
        </swiper>
        <div class="event-swiper-control">
          <div class="btn-prev"></div>
          <div class="pager"></div>
          <div class="btn-next"></div>
        </div>
        <div class="events-title" v-if="eventsSwiperData[eventsSwiperIndex]">
          {{ eventsSwiperData[eventsSwiperIndex].title }}
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.news {
  background-image: url('../assets/images/home/bg-news.jpg');
}
.events {
  background-image: url('../assets/images/home/bg-events.jpg');
  padding-top: 10px;
}

.title-events {
  background-image: url('../../public/images/common/title-events.png');
}
.title-news {
  background-image: url('../../public/images/common/title-news.png');
}
.swiper-news {
  padding-bottom: 0;
  max-width: 1920px;
}
.swiper-news .swiper-slide {
  width: 1200px;
  height: 394px;
}
.swiper-news .swiper-slide a {
  display: block;
  position: relative;
}
.swiper-news .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
}
.swiper-news-container {
  position: relative;
  margin-bottom: 100px;
}
.news-desc {
  display: block;
  left: 50%;
  margin-left: -600px;
  width: 628px;
  height: 169px;
  background-color: rgba(0, 0, 0, 0.65);
  position: absolute;
  line-height: 40px;
  text-align: left;
  color: #fff;
  letter-spacing: 0.06em;
  background-image: linear-gradient(90deg, rgb(0, 78, 162) 0%, rgb(7, 30, 59) 100%);
  opacity: 0.902;
  bottom: -50px;
  z-index: 1;
  font-size: var(--font-size-xl);
  padding: 28px 30px 0;
}

.news .pager {
  position: absolute;
  right: 20px;
  bottom: 0;
  text-align: right;
}
.cards-container {
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
}
.cards-btn {
  width: 100%;
  text-align: center;
}
.swiper-events {
  overflow: visible;
}
.swiper-events .swiper-slide {
  height: 430px;
}
.swiper-events img {
  border: 7px solid #d3e9ff;
  width: 100%;
  aspect-ratio: 16/10;
  box-shadow: 0 0 30px 1px rgb(128 204 255 / 30%);
  transition: all 0.3s;
}
.swiper-events img:hover {
  transform: scale(1.02);
}
.event-swiper-control {
  text-align: center;
}
.btn-prev {
  background: url('../assets/images/home/left.png') no-repeat center;
  width: 46px;
  height: 54px;
  display: inline-block;
  cursor: pointer;
}
.btn-next {
  background: url('../assets/images/home/right.png') no-repeat center;
  width: 46px;
  height: 54px;
  display: inline-block;
  cursor: pointer;
}
.events .pager {
  display: inline-block;
  width: 160px;
  height: 54px;
  line-height: 54px;
  position: static;
  vertical-align: top;
  color: #fff;
  font-size: 34px;
  font-weight: 600;
  user-select: none;
}
.events-title {
  background: url('../assets/images/home/bg-text.png') no-repeat bottom center;
  background-size: 100% auto;
  height: 160px;
  color: #fff;
  margin-top: 80px;
  padding: 40px 50px;
  margin-bottom: 180px;
  font-size: var(--font-size-xl);
  letter-spacing: 0.02em;
  line-height: 1.5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.news-title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
}

@media (max-width: 768px) {
  .swiper-news {
    max-width: 100%;

    height: 6rem;
    margin-bottom: 0.5rem;
  }
  .swiper-news .swiper-slide {
    height: 3.9375rem;
  }

  .swiper-news .swiper-slide img {
    height: 3.9375rem;

    object-fit: cover;
    position: relative;
    display: block;
  }
  .swiper-news-container {
    margin-bottom: 0.5rem;
  }

  .news-desc {
    width: 7rem;
    margin-left: -3.5rem;
    bottom: 0rem;
    height: 2.5rem;
    padding: 0.4rem 0.3rem 0;
    line-height: 1.6em;
    font-size: var(--font-size-xl-m);
  }
  .cards-container {
    flex-direction: column;
    padding-bottom: 0rem;
  }

  .news {
    background-image: url('../assets/images/home/m/bg-news-m.jpg');
  }
  .events {
    background-image: url('../assets/images/home/m/bg-events-m.jpg');
    padding-top: 10px;
  }
  .title-news {
    background-image: url('../../public/images/common/title-news-m.png');
  }
  .title-events {
    background-image: url('../../public/images/common/title-events-m.png');
  }
  .swiper-events {
    overflow: hidden;
  }
  .swiper-events .swiper-slide {
    height: 5rem;
  }
  .events-title {
    background: url('../assets/images/home/m/bg-text-m.png');
    height: 2.74rem;
    width: 6.86rem;
    margin: 0.5rem auto 2rem;
    background-size: 100% 100%;
    padding: 0 0.4rem;
    font-size: var(--font-size-xl-m);
  }

  .events .pager {
    width: 1.8rem;
    height: 0.8rem;
    line-height: 0.8rem;
    font-size: 0.4rem;
  }
  .btn-next,
  .btn-prev {
    width: 0.6rem;
    height: 0.8rem;
    background-size: 100% 100%;
  }
  .cards-btn {
    margin-bottom: 0.6rem;
  }
}
</style>

<style>
.news-desc .swiper-pagination-bullet {
  height: 4px !important;
  width: 40px !important;
  border-radius: 0 !important;
  margin: 0 3px !important;
  background: rgba(255, 255, 255, 1) !important;
}
.news-desc .swiper-pagination-bullet-active {
  background: rgba(255, 255, 255, 1);
}

.news-desc .news-title a {
  color: #fff;
}
.events-title a {
  color: #fff;
}
@media (max-width: 768px) {
  .swiper-news .swiper-pagination-bullet {
    height: 0.05rem !important;
    width: 0.5rem !important;

    margin: 0 0.03rem !important;
    background: rgba(255, 255, 255, 1) !important;
  }
}
</style>
