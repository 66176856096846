
import Home from '../views/Home.vue'
import Events from '../views/Events.vue'
import About from '../views/About.vue'
import Resources from '../views/Resources.vue'
import Article from '../views/Article.vue'
import NewsList from '../views/NewsList.vue'
import { MolaTrack } from '../utils/MolaTrack'  
const routes= [
  {path:'/',component:Home,  
  meta: {
    moduleName: 'home',
    title:'Children & Youth Science Center,China Association For Science & Technology'
  }},
  {path:'/events',component:Events,
  meta: {
    moduleName: 'events',
    title:'Children & Youth Science Center,China Association For Science & Technology'
  }},
  {path:'/about',component:About,
  meta: {
    moduleName: 'about',
    title:'Children & Youth Science Center,China Association For Science & Technology'
  }},
  {path:'/resources',component:Resources,
  meta: {
    moduleName: 'resources',
    title:'Children & Youth Science Center,China Association For Science & Technology'
  }},
  {path:'/article',component:Article,
  meta: {
    moduleName: 'article',
    title:'Children & Youth Science Center,China Association For Science & Technology'
  }
},
  {path:'/news',component:NewsList,
  meta: {
    moduleName: 'news',
    title:'Children & Youth Science Center,China Association For Science & Technology'
  }}
]
//进入每个路由前都会执行
routes.forEach((route) => {
  route.beforeEnter = (to, from, next) => {
    if(to.meta && !to.meta.noTrace){
      MolaTrack(to.path,null,to.meta.title,to.meta.moduleName,'en');
   }
    next()
  }
})

export default routes