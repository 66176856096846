<script>

import { findNewsCardById } from '@/api/index'


export default {
  name: 'Article',
  data () {
    return {
      articleData: {},
    }
  },
  computed: {
    publishDate () {
      const date = new Date(this.articleData.addTime)
      return date.toLocaleDateString('en-us', { year: 'numeric', month: 'long', day: 'numeric' })
    }

  },
  methods: {
    getData () {
      findNewsCardById(this.$route.query.id).then(res => {
        if (res.code == 200) {
          this.articleData = res.data
        }
      })
    }
  },
  created () {
    this.getData()
  }
}

</script>

<template>
  <div class="page">
    <div class="block-full-width article">
      <div class="main">
        <div class="title-cotnainer">
          <div class="article-title" v-html="articleData.title"></div>
          <div class="article-info">
            <div class="publish-time">{{ publishDate }}</div>
            <div class="view-count">{{ articleData.readCount }}</div>
            <div class="up-count">{{ articleData.upcount }}</div>
          </div>
          <div class="line"></div>
        </div>

        <div class="article-text" v-html="articleData.content"></div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.line {
  background: url('../assets/images/common/line.png') no-repeat center;
  height: 3px;
  margin-top: 10px;
  margin-bottom: 40px;
}
.article {
  background-image: url('../assets/images/article/bg.jpg');
  padding-bottom: 50px;
  background-size: cover;
  background-position-y: top;
  min-height: var(--bodyContentMinHeight);
}
.article-title {
  font-size: 30px;
  margin-top: 80px;
  color: var(--main-color-1);
  font-weight: bold;
}
.article-info {
  display: flex;
  align-items: center;
  gap: 20px;
  line-height: 40px;
}
.article-text {
  line-height: 1.8;
  letter-spacing: 0.5px;
}
.article-info .publish-time {
  background: url('../assets/images/article/icon-time.png') no-repeat center;
  background-position: left center;
  background-size: 19px 18px;
  padding-left: 25px;
}
.article-info .view-count {
  background: url('../assets/images/article/icon-view.png') no-repeat center;
  background-position: left center;
  background-size: 24px 15px;
  padding-left: 30px;
}
.article-info .up-count {
  background: url('../assets/images/article/icon-zan.png') no-repeat center;
  background-position: left center;
  background-size: 18px 18px;
  padding-left: 25px;
  display: none !important;
}

.article-text /deep/ img {
  max-width: 100%;
}

@media (max-width: 768px) {
  .article {
    background-image: url('../assets/images/article/bg-m.jpg');
    padding-bottom: 2rem;
  }

  .article-title {
    font-size: 0.5rem;
    margin-top: 0.6rem;
    color: var(--main-color-1);
  }

  .article-info {
    gap: 1.6em;
    line-height: 1rem;
  }
  .article-text {
    letter-spacing: 0.01rem;
  }
  .article-info .publish-time {
    background-image: url('../assets/images/article/icon-time-m.png');
    background-size: 0.27rem 0.27rem;
    padding-left: 0.38rem;
  }
  .article-info .view-count {
    background-image: url('../assets/images/article/icon-view-m.png');
    background-size: 0.36rem 0.23rem;
    padding-left: 0.5rem;
  }
  .article-info .up-count {
    background-image: url('../assets/images/article/icon-zan-m.png');
    background-size: 0.27rem 0.27rem;
    padding-left: 0.38rem;
  }

  .article-text /deep/ img {
    max-width: 100%;
  }
}
</style>