<script >
export default {
  name: 'CardSimple',
  props: {
    title: String,
    desc :String,
    link :String,
    src :String,
    id:Number,
    date:String,
    height:String
  },
  data() {
    return {
     
    };
  },
  computed:{
    cardHeight(){
      return{
        '--cardHeight':this.height?this.height:window.innerWidth>768?'425px':'6.5rem'
      }
    },
  },
  methods:{
    newClick(){
      this.$router.push({
        path: this.link,
        query: {
          id: this.id
        }
      })
    }
  }
}
</script>
<template>
  <div class="card-simple" @click="newClick()" :style="cardHeight">
   <a>
     <img :src="src" alt="">
     <div class="text-wrapper">
      <div class="news-title" v-html="title"></div>
     <div class="desc" v-if="desc" v-html="desc"></div>
      <div class="article-info" v-if="date">
        <div class="publish-time">{{date}}</div>
      </div>
     </div>
   </a>
  </div>
</template>

<style scoped>
.card-simple{
  width:379px;
  /* height:425px; */
  height: var(--cardHeight);
  background: #fff;
  overflow: hidden;
  transition:all .3s;
  box-shadow: 0 0 20px 1px rgb(128 204 255 / 20%);
  cursor: pointer;
}
.card-simple:hover{
  transform: scale(1.02);
}
.card-simple img{
  width: 100%;
  height: 214px;
}
.text-wrapper{
  margin:10px 30px 20px;
}

.news-title{
  font-size:var(--font-size-l);
  font-weight: 650;
  height:60px;
     display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
}
.desc{
  display: -webkit-box;
  -webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 4;

}
.article-info{
  display: flex;
  align-items: center;
  gap:20px;
  line-height:40px;
  margin-top: 25px;
}
.article-info .publish-time{
  background:url("../assets/images/article/icon-time.png") no-repeat center;
  background-position: left center;
  background-size:19px 18px;
  padding-left:25px;
  color: #666666;
}

@media (max-width: 768px) {
  .card-simple{
    width:7rem;
    /* height:6.5rem; */
    height: var(--cardHeight);
    margin-bottom:.6rem;
  }
  .card-simple img {
    width: 100%;
    height: 3.9375rem;
}
.desc{
  -webkit-line-clamp: 3;
}
.news-title{
  height:auto;
  max-height:3em;
  height: 3em;
  line-height: 1.5em;
  margin-bottom:.1rem;
  font-size:var(--font-size-l-m);
  overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box; 
    -webkit-line-clamp: 2; 
    -webkit-box-orient: vertical;
}
.article-info{
  gap:1.6em;
  line-height:0.5rem;
  margin-top:0.25rem;

}
.article-info .publish-time{
  background-image:url("../assets/images/article/icon-time-m.png");
  background-size:.27rem .27rem;
  padding-left:.38rem;
}
}

</style>
